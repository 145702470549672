@font-face {
  font-family: "Roboto-Light";
  src: url("../assets/fonts/Roboto-Light.eot");
  src: url("../assets/fonts/Roboto-Light.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/Roboto-Light.svg#Roboto-Light") format("svg"),
    url("../assets/fonts/Roboto-Light.ttf") format("truetype"),
    url("../assets/fonts/Roboto-Light.woff") format("woff"),
    url("../assets/fonts/Roboto-Light.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto-Regular";
  src: url("../assets/fonts/Roboto-Regular.eot");
  src: url("../assets/fonts/Roboto-Regular.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/Roboto-Regular.svg#Roboto-Regular") format("svg"),
    url("../assets/fonts/Roboto-Regular.ttf") format("truetype"),
    url("../assets/fonts/Roboto-Regular.woff") format("woff"),
    url("../assets/fonts/Roboto-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Roboto-Medium";
  src: url("../assets/fonts/Roboto-Medium.eot");
  src: url("../assets/fonts/Roboto-Medium.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/Roboto-Medium.svg#Roboto-Medium") format("svg"),
    url("../assets/fonts/Roboto-Medium.ttf") format("truetype"),
    url("../assets/fonts/Roboto-Medium.woff") format("woff"),
    url("../assets/fonts/Roboto-Medium.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto-Bold";
  src: url("../assets/fonts/Roboto-Bold.eot");
  src: url("../assets/fonts/Roboto-Bold.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/Roboto-Bold.svg#Roboto-Bold") format("svg"),
    url("../assets/fonts/Roboto-Bold.ttf") format("truetype"),
    url("../assets/fonts/Roboto-Bold.woff") format("woff"),
    url("../assets/fonts/Roboto-Bold.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Roboto-Light";
  src: url("../assets/fonts/Roboto-Light.eot");
  src: url("../assets/fonts/Roboto-Light.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/Roboto-Light.svg#Roboto-Light") format("svg"),
    url("../assets/fonts/Roboto-Light.ttf") format("truetype"),
    url("../assets/fonts/Roboto-Light.woff") format("woff"),
    url("../assets/fonts/Roboto-Light.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
/*Base*/
html {
  font-size: 18px;
  font-family: "Roboto-Light";
}
